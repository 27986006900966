<template>
  <div class="louckyPokerBox" ref="louckyPockerBox" @scroll="pageScroll" :class="popFlag ? 'louckyPokerBoxMinHeight' : ''">
     <header class="titleBox"> 
     </header>
     <div class="timeBox">
        <p v-if="activeState == 1">{{ $t("luckyEventStartsIn") }}</p>
        <p v-else-if="activeState == 2">{{ $t("luckyEventEndsIn") }}</p>
        <p v-else>{{ $t("luckyEventEnd") }}</p>
      <div v-if="$i18n.locale == 'ar'">
          <span>{{hours[1]}}</span>
        <span>{{hours[0]}}</span>
        <i>:</i>
          <span>{{minutes[1]}}</span>
          <span>{{minutes[0]}}</span>
        <i>:</i>
          <span>{{seconds[1]}}</span>
          <span>{{seconds[0]}}</span>
      </div>
      <div v-else>
        <span>{{hours[0]}}</span>
          <span>{{hours[1]}}</span>
        <i>:</i>
          <span>{{minutes[0]}}</span>
          <span>{{minutes[1]}}</span>
        <i>:</i>
          <span>{{seconds[0]}}</span>
          <span>{{seconds[1]}}</span>
      </div>
     </div>
     <div class="instructionBox">
      <p>{{ $t("luckyEventInstruction") }}</p>
      <div class="instructionBody">
        <header>{{ $t("luckySendingFollowing") }}</header>
        <ul class="instructionItem">
          <li>
            <div class="giftBox">
              <img src="../../assets/luckyPoker/gift1.png" alt="">
            </div>
            <span>{{ $t("luckyGoldenBoot") }}</span>
          </li>
          <li>
            <div class="giftBox">
              <img src="../../assets/luckyPoker/gift2.png" alt="">
            </div>
            <span>{{ $t("luckyFootball") }}</span>
          </li>
          <li>
            <div class="giftBox">
              <img src="../../assets/luckyPoker/gift3.png" alt="">
            </div>
            <span>{{ $t("luckyDefensiveMaster") }}</span>
          </li>
          <li>
            <div class="giftBox">
              <img src="../../assets/luckyPoker/gift4.png" alt="">
            </div>
            <span>{{ $t("luckyTheWorldCup") }}</span>
          </li>
        </ul>
      </div>
     </div>
     <div class="rankingBox">
        <div class="rankingTitle">
          <span :class="rankListFlag == 1 ? 'activeItle' : ''" @click="changeRankListFlag(1)">{{ $t("luckyRoom") }}</span>
          <span :class="rankListFlag == 3 ? 'activeItle' : ''" @click="changeRankListFlag(3)">{{ $t("luckyAnchors") }}</span>
          <span :class="rankListFlag == 2 ? 'activeItle' : ''" @click="changeRankListFlag(2)">{{ $t("luckyGifter") }}</span>
          <i @click="changePopFlag(1)">
            <i></i>
          </i>
        </div>
        <div class="roomBox" v-if="rankListFlag == 1 ">
          <div class="roomTips">
            <span></span>
            <span>{{ $t("luckyRoomTips") }}</span>
          </div>
          <div class="roomList" v-if="roomTopObj.length > 0">
            <header class="roomTop3">
              <div class="roomTop2Box"> 
                <p class="pictureBox">
                  <img @click="toAppRoom(roomTopObj[1])" v-if="roomTopObj[1] && roomTopObj[1].headIcon" :src="roomTopObj[1].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/fang.png" alt="">
                  <img v-if="roomTopObj[1].score > luckScore" class="roomTipsIcon" src="../../assets/luckyPoker/roomTips.png" alt="">
                </p>
                <p class="userName" v-if="roomTopObj[1]">{{ roomTopObj[1].roomName}}</p>
                <p class="userMoney" v-if="roomTopObj[1]">{{roomTopObj[1].score | formatNumber}}</p>
              </div>
              <div class="roomTop1Box">
                <p class="pictureBox">
                  <img @click="toAppRoom(roomTopObj[0])" v-if="roomTopObj[0] && roomTopObj[0].headIcon" :src="roomTopObj[0].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/fang.png" alt="">
                  <img v-if="roomTopObj[0].score > luckScore" class="roomTipsIcon" src="../../assets/luckyPoker/roomTips.png" alt="">
                </p>
                <p class="userName" v-if="roomTopObj[0]">{{ roomTopObj[0].roomName}}</p>
                <p class="userMoney" v-if="roomTopObj[0]">{{roomTopObj[0].score | formatNumber}}</p>
              </div>
              <div class="roomTop3Box">
                <p class="pictureBox">
                  <img @click="toAppRoom(roomTopObj[2])" v-if="roomTopObj[2] && roomTopObj[2].headIcon" :src="roomTopObj[2].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/fang.png" alt="">
                  <img v-if="roomTopObj[2].score > luckScore" class="roomTipsIcon" src="../../assets/luckyPoker/roomTips.png" alt="">
                </p>
                <p class="userName" v-if="roomTopObj[2]">{{ roomTopObj[2].roomName}}</p>
                <p class="userMoney" v-if="roomTopObj[2]">{{roomTopObj[2].score | formatNumber}}</p>
              </div>
            </header>
            <ul class="top3After">
                <template v-for="(item,index) in roomTopObj">
                  <li  v-if="index > 2">
                    <p class="rankingNum">{{ item.top | zeroFillTop }}</p>
                    <p class="pictureBox">
                      <img @click="toAppRoom(item)" :src="item.headIcon" alt="">
                    </p>
                    <p class="userName">{{ item.roomName }}</p>
                    <img class="roomTipsIconList" v-if="item.score > luckScore" src="../../assets/luckyPoker/roomTips.png" alt="">
                    <p class="userMoney">{{ item.score | formatNumber}}</p>
                  </li>
              </template>
            </ul>
          </div>
          <div class="nullBox" v-else>
            <p></p>
            <div>{{ $t("luckyNoOne") }}</div>
          </div>
        </div>
        <div class="gifterBox gifterBox2" v-if="rankListFlag == 2 ">
          <div class="gifterList" v-if="gifterTopObj.length > 0">
            <header class="roomTop3">
              <div class="roomTop2Box">
                <p class="pictureBox">
                  <img @click="skipUserInfo(gifterTopObj[1])" v-if="gifterTopObj[1] && gifterTopObj[1].headIcon" :src="gifterTopObj[1].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                </p>

                <p class="userName" v-if="gifterTopObj[1]">{{ gifterTopObj[1].nickname}}</p>
                <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + gifterTopObj[1].vipLevel] " v-if="gifterTopObj[1] && gifterTopObj[1].vipLevel > 0">{{ gifterTopObj[1].vipLevel }}</span>
                  <span v-if="gifterTopObj[1] && gifterTopObj[1].wealthLevel > 0" :class="['wealth', countClass(gifterTopObj[1].wealthLevel)]">
                    <span>{{ gifterTopObj[1].wealthLevel }}</span>
                  </span>
                  <!-- <span class="charm charm1">1</span> -->
                </p>
                <p class="userMoney" v-if="gifterTopObj[1]">{{gifterTopObj[1].score | formatNumber}}</p>
              </div>
              <div class="roomTop1Box">
                <p class="pictureBox">
                  <img @click="skipUserInfo(gifterTopObj[0])" v-if="gifterTopObj[0] && gifterTopObj[0].headIcon" :src="gifterTopObj[0].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                </p>

                <p class="userName" v-if="gifterTopObj[0]">{{ gifterTopObj[0].nickname}}</p>
                <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + gifterTopObj[0].vipLevel]" v-if="gifterTopObj[0] && gifterTopObj[0].vipLevel > 0">{{ gifterTopObj[0].vipLevel }}</span>
                  <span v-if="gifterTopObj[0] && gifterTopObj[0].wealthLevel > 0" :class="['wealth', countClass(gifterTopObj[0].wealthLevel)]">
                    <span>{{ gifterTopObj[0].wealthLevel }}</span>
                  </span>
                  <!-- <span class="charm charm1">1</span> -->
                </p>
                <p class="userMoney" v-if="gifterTopObj[0]">{{gifterTopObj[0].score | formatNumber}}</p>
              </div>
              <div class="roomTop3Box">
                <p class="pictureBox">
                  <img @click="skipUserInfo(gifterTopObj[2])" v-if="gifterTopObj[2] && gifterTopObj[2].headIcon" :src="gifterTopObj[2].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                </p>

                <p class="userName" v-if="gifterTopObj[2]">{{ gifterTopObj[2].nickname}}</p>
                <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + gifterTopObj[2].vipLevel]" v-if="gifterTopObj[2] && gifterTopObj[2].vipLevel > 0">{{ gifterTopObj[2].vipLevel }}</span>
                  <span v-if="gifterTopObj[2] && gifterTopObj[2].wealthLevel > 0" :class="['wealth', countClass(gifterTopObj[2].wealthLevel)]">
                    <span>{{ gifterTopObj[2].wealthLevel }}</span>
                  </span>
                  <!-- <span class="charm charm1">1</span> -->
                </p>
                <p class="userMoney" v-if="gifterTopObj[2]">{{gifterTopObj[2].score | formatNumber}}</p>
              </div>
            </header>
            <ul class="top3After">
              <template v-for="(item,index) in gifterTopObj">
                <li v-if="index > 2">
                  <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                  <p class="pictureBox">
                      <img @click="skipUserInfo(item)" :src="item.headIcon" alt="">
                  </p>
                  <div class="gifterRankingCenter">
                    <p class="userName">{{ item.nickname }}</p>
                    <p class="gradeIconBox">
                    <span :class="['vip', 'vip' + item.vipLevel]" v-if="item.vipLevel > 0">{{ item.vipLevel }}</span>
                    <span v-if="item.wealthLevel > 0" :class="['wealth', countClass(item.wealthLevel)]">
                      <span>{{ item.wealthLevel }}</span>
                    </span>
                      <!-- <span class="charm charm1">1</span> -->
                    </p>
                  </div>
                  <p class="userMoney">{{item.score | formatNumber}}</p>
                </li>
              </template>

              
            </ul>
          </div>
          <div class="nullBox" v-else>
            <p></p>
            <div>{{ $t("luckyNoOne") }}</div>
          </div>
        </div>
        <div class="gifterBox" v-if="rankListFlag == 3 ">
          <div class="gifterList" v-if="anchorsTopObj.length > 0">
            <header class="roomTop3">
              <div class="roomTop2Box">
                <p class="pictureBox">
                  <img @click="skipUserInfo(anchorsTopObj[1])" v-if="anchorsTopObj[1] && anchorsTopObj[1].headIcon" :src="anchorsTopObj[1].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                </p> 

                <p class="userName" v-if="anchorsTopObj[1]">{{ anchorsTopObj[1].nickname}}</p>
                <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + anchorsTopObj[1].vipLevel] " v-if="anchorsTopObj[1] && anchorsTopObj[1].vipLevel > 0">{{ anchorsTopObj[1].vipLevel }}</span>
                  <span v-if="anchorsTopObj[1] && anchorsTopObj[1].wealthLevel > 0" :class="['wealth', countClass(anchorsTopObj[1].wealthLevel)]">
                    <span>{{ anchorsTopObj[1].wealthLevel }}</span>
                  </span>
                  <!-- <span class="charm charm1">1</span> -->
                </p>
                <p class="userMoney" v-if="anchorsTopObj[1]">{{anchorsTopObj[1].score | formatNumber}}</p>
              </div>
              <div class="roomTop1Box">
                <p class="pictureBox">
                  <img @click="skipUserInfo(anchorsTopObj[0])" v-if="anchorsTopObj[0] && anchorsTopObj[0].headIcon" :src="anchorsTopObj[0].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                </p>

                <p class="userName" v-if="anchorsTopObj[0]">{{ anchorsTopObj[0].nickname}}</p>
                <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + anchorsTopObj[0].vipLevel]" v-if="anchorsTopObj[0] && anchorsTopObj[0].vipLevel > 0">{{ anchorsTopObj[0].vipLevel }}</span>
                  <span v-if="anchorsTopObj[0] && anchorsTopObj[0].wealthLevel > 0" :class="['wealth', countClass(anchorsTopObj[0].wealthLevel)]">
                    <span>{{ anchorsTopObj[0].wealthLevel }}</span>
                  </span>
                  <!-- <span class="charm charm1">1</span> -->
                </p>
                <p class="userMoney" v-if="anchorsTopObj[0]">{{anchorsTopObj[0].score | formatNumber}}</p>
              </div>
              <div class="roomTop3Box">
                <p class="pictureBox">
                  <img @click="skipUserInfo(anchorsTopObj[2])" v-if="anchorsTopObj[2] && anchorsTopObj[2].headIcon" :src="anchorsTopObj[2].headIcon" alt="">
                  <img v-else src="../../assets/luckyPoker/yuan.png" alt="">
                </p>

                <p class="userName" v-if="anchorsTopObj[2]">{{ anchorsTopObj[2].nickname}}</p>
                <p class="gradeIconBox">
                  <span :class="['vip', 'vip' + anchorsTopObj[2].vipLevel]" v-if="anchorsTopObj[2] && anchorsTopObj[2].vipLevel > 0">{{ anchorsTopObj[2].vipLevel }}</span>
                  <span v-if="anchorsTopObj[2] && anchorsTopObj[2].wealthLevel > 0" :class="['wealth', countClass(anchorsTopObj[2].wealthLevel)]">
                    <span>{{ anchorsTopObj[2].wealthLevel }}</span>
                  </span>
                  <!-- <span class="charm charm1">1</span> -->
                </p>
                <p class="userMoney" v-if="anchorsTopObj[2]">{{anchorsTopObj[2].score | formatNumber}}</p>
              </div>
            </header>
            <ul class="top3After">
              <template v-for="(item,index) in anchorsTopObj">
                <li v-if="index > 2">
                  <p class="rankingNum">{{ item.top |  zeroFillTop}}</p>
                  <p class="pictureBox">
                      <img @click="skipUserInfo(item)" :src="item.headIcon" alt="">
                  </p>
                  <div class="gifterRankingCenter">
                    <p class="userName">{{ item.nickname }}</p>
                    <p class="gradeIconBox">
                    <span :class="['vip', 'vip' + item.vipLevel]" v-if="item.vipLevel > 0">{{ item.vipLevel }}</span>
                    <span v-if="item.wealthLevel > 0" :class="['wealth', countClass(item.wealthLevel)]">
                      <span>{{ item.wealthLevel }}</span>
                    </span>
                      <!-- <span class="charm charm1">1</span> -->
                    </p>
                  </div>
                  <p class="userMoney">{{item.score | formatNumber}}</p>
                </li>
              </template>

              
            </ul>
          </div>
          <div class="nullBox" v-else>
            <p></p>
            <div>{{ $t("luckyNoOne") }}</div>
          </div>
        </div>
        
     </div>
     <div class="popBox" ref="popBox" v-show="popFlag">
      <div class="popBody">
        <header>{{ $t("luckyRules") }}</header>
        <span class="closeBtn" @click="changePopFlag(0)"></span>
        <div class="popScroll">
          <div class="popRulesMsg">
          <div>
            <p>{{ $t("luckyHowTo") }}</p>
            <!-- <p>How to get i</p> -->
          </div>
          <p>{{ $t("luckyTheGifts") }}</p>
        </div>
        <div class="rewardsBox">
          <p class="rewardsTitle">{{ $t("luckyRewards") }}</p>
          <div class="rewardsTabBox">
            <div class="rewardsTabContent">
              <div class="roomRewards">
                  <p class="demoBg"></p>
                  <div class="blessingBox">
                    <p class="blessingHead">{{ $t("luckyBlessingCard") }}</p>
                    <!-- <div class="blessingBody">
                      <div>
                        <img src="../../assets/luckyPoker/headerBg.png" alt="">
                        <span>{{ $t("luckyBlessingCard") }}</span>
                      </div>
                      <div>
                        <img src="../../assets/luckyPoker/headerBg.png" alt="">
                        <span>{{ $t("luckyBlessingCard") }}</span>
                      </div>
                    </div> -->
                  </div>
                  <div class="rewardsTabHead">
                    <p :class="rulesTab == 1 ? 'activeHead' : ''" @click="changeRulesTab(1)">{{ $t("luckyRoomReward") }}</p>
                    <p :class="rulesTab == 2 ? 'activeHead' : ''"  @click="changeRulesTab(2)">{{ $t("luckyGifterRewards") }}</p>
                  </div>
                  <ul class="giftMsgBox">
                    <li class="top1GiftMsg rewardDetailOne" v-show="rulesTab == 1">
                      <div class="topRight">
                        <p><span>Top1</span><span>{{ $t('luckyTop_1') }}</span></p>
                        <p><span>Top2</span><span>{{ $t('luckyTop_2') }}</span></p>
                        <p><span>Top3</span><span>{{ $t('luckyTop_3') }}</span></p>
                      </div>
                    </li>
                    <li class="top1GiftMsg" v-show="rulesTab == 2">
                      <div class="topRight">
                        <p><span>Top1</span><span>{{ $t('luckyTop_1_1') }}</span></p>
                        <p><span>Top2</span><span>{{ $t('luckyTop_2_1') }}</span></p>
                        <p><span>Top3</span><span>{{ $t('luckyTop_3_1') }}</span></p>
                      </div>
                    </li>
                  </ul>
              </div>
              <!-- <div class="gifterRewards" v-if="rulesTab == 2"></div> -->
              </div>
            </div>
          </div>
        </div>
       
      </div>
     </div>
     <!-- 个人信息 -->
     <div class="userInfoBox" v-if="gifterSelfInfo || roomSelfInfo || anchorsSelfInfo">
      <!-- 房间 -->
      <div class="userInfoRoom" v-if="rankListFlag == 1 && roomSelfInfo">
        <p class="rankingNum">{{ roomSelfInfo.top | zeroFillTop }}</p>
        <p class="pictureBox">
          <img :src="roomSelfInfo.headIcon" alt="">
        </p>
        <p class="userName">{{ roomSelfInfo.roomName }}</p>
        <p class="userMoney">{{ roomSelfInfo.score | formatNumber }}</p>
      </div>
      <!-- 个人送礼信息  -->
      <div class="userInfoGifter" v-if="rankListFlag == 2 && gifterSelfInfo">
        <p class="rankingNum">{{ gifterSelfInfo.top | topFilter}}</p>
        <p class="pictureBox">
          <img :src="gifterSelfInfo.headIcon" alt="">
        </p>
        <div class="gifterRankingCenter">
          <p class="userName">{{ gifterSelfInfo.nickname }}</p>
          <p class="gradeIconBox">
            <span :class="['vip', 'vip' + gifterSelfInfo.vipLevel]" v-if="gifterSelfInfo.vipLevel > 0"></span>
            <span v-if="gifterSelfInfo.wealthLevel > 0" :class="['wealth', countClass(gifterSelfInfo.wealthLevel)]">
              <span>{{ gifterSelfInfo.wealthLevel }}</span>
            </span>
            <!-- <span class="charm charm1">1</span> -->
          </p>
        </div>
        <p class="userMoney">{{gifterSelfInfo.score | formatNumber }}</p>
      </div>
      <!-- 个人收礼信息 -->
      <div class="userInfoGifter" v-if="rankListFlag == 3 && anchorsSelfInfo">
        <p class="rankingNum">{{ anchorsSelfInfo.top | topFilter}}</p>
        <p class="pictureBox">
          <img :src="anchorsSelfInfo.headIcon" alt="">
        </p>
        <div class="gifterRankingCenter">
          <p class="userName">{{ anchorsSelfInfo.nickname }}</p>
          <p class="gradeIconBox">
            <span :class="['vip', 'vip' + anchorsSelfInfo.vipLevel]" v-if="anchorsSelfInfo.vipLevel > 0"></span>
            <span v-if="anchorsSelfInfo.wealthLevel > 0" :class="['wealth', countClass(anchorsSelfInfo.wealthLevel)]">
              <span>{{ anchorsSelfInfo.wealthLevel }}</span>
            </span>
            <!-- <span class="charm charm1">1</span> -->
          </p>
        </div>
        <p class="userMoney">{{anchorsSelfInfo.score | formatNumber }}</p>
      </div>
     </div>
  </div>
 
</template>

<script>
import { Toast } from "vant";
export default {
  name: "luckyPoker",
  data() {
    return {
      // 榜单展示
      rankListFlag: 1,
      // 规则弹窗
      popFlag: 0,
      // 规则tab切换
      rulesTab: 1,
      // 活动id
      activityId:0,
      // 活动信息
      activityObj:{},
      // 活动时间
      hours:[0,0],
      minutes:[0,0],
      seconds:[0,0], 
      page: 0,
      size: 20,
      // 房间榜单
      roomTopObj:[],
      // 个人送礼榜单
      gifterTopObj:[],
      // 个人收礼榜单
      anchorsTopObj:[],
      // 避免重复请求
      notFast: true,
      // 个人榜送礼个人信息
      gifterSelfInfo:null,
      // 个人榜收礼个人信息
      anchorsSelfInfo:null,
      // 房间榜个人信息
      roomSelfInfo:null,
      wealthArr:['wealth'],
      // 活动状态
      activeState: 0,
      // 显示奖杯的活动分数限制
      luckScore: 0
    }
  },
  beforeCreate(){
  },
  created() {
    this.activityId = this.$route.query.id
    //调用原生方法
    this.$completes.complete("getUserInfo");
    //原生调用h5方法
    window.getUserInfo = this.getUserInfo;
    window["getUserInfo"] = (data) => {
      this.getUserInfo(data);
    };
    // 判断平台
    this.judgeClient()

    // this.getUserInfo() 

  },
  filters:{
    formatNumber(num) {
      if (num > 1000000) {
          return (num / 1000000).toFixed(1) + 'M';
      } else if (num >= 1000) {
          return (num / 1000).toFixed(1) + 'K';
      } else {
          return num.toString ();
      }
    },
    topFilter(top){
      if(top > 99){
        return '99+';
      }else if(top == 0){
        return '--'
      }else{
        return top > 9 ? top : '0' + top
      }
    },
    zeroFillTop(top){
      if(top < 10){
        return '0'+top;
      }else{
        return top;
      }
    },
  },
  methods: { 
    // 动态计算class¸¸
    countClass(levele){
      let className = '';
      if(levele > 0 && levele < 15){
        className = 'wealth1_15';
      }else if(levele > 15 && levele <= 30){
        className = 'wealth16_30';
      }else if(levele > 30 && levele <= 45){
        className = 'wealth31_45';
      }else if(levele > 45 && levele <= 60){
        className = 'wealth46_60';
      }else if(levele > 60 && levele <= 75){
        className = 'wealth61_75';
      }else if(levele > 75 && levele <= 99){
        className = 'wealth76_99';
      }else if(levele > 99 && levele <= 109){
        className = 'wealth100_109';
      }else if(levele > 109 && levele <= 119){
        className = 'wealth110_119';
      }else if(levele > 119 && levele <= 129){
        className = 'wealth120_129';
      }else if(levele > 129 && levele <= 139){
        className = 'wealth130_139';
      }else if(levele > 139 && levele <= 149){
        className = 'wealth140_149';
      }else if(levele > 149 && levele <= 159){
        className = 'wealth150_159';
      }else if(levele > 159 && levele <= 169){
        className = 'wealth160_169';
      }else if(levele > 169 && levele <= 179){
        className = 'wealth170_179';
      }else if(levele > 179 && levele <= 189){
        className = 'wealth180_189';
      }else if(levele > 189 && levele <= 200){
        className = 'wealth190_200';
      }
      // this.wealthArr.push(className);
      return className;
    },
    // 获取自己当前收礼排名信息
    getAnchorsSelfRankInfo(){
      let rankInfo = {
        cfgId:this.activityObj.id,
        rankType: 2
      };
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/modulerank/role/rank?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          rankInfo.rankType+
          "&cfgId=" +
          rankInfo.cfgId+
          "&type=" +
          rankInfo.type,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
         this.anchorsSelfInfo = data.data;
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    // 获取自己当前排名信息
    getGifterSelfRankInfo(){
      let rankInfo = {
        cfgId:this.activityObj.id,
        rankType: 1
      };
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/modulerank/role/rank?token=" +
          this.token +
          "&uid=" +
          this.uid+
          "&rankType=" +
          rankInfo.rankType+
          "&cfgId=" +
          rankInfo.cfgId+
          "&type=" +
          rankInfo.type,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        console.log(data,'0000')
        if (data.code == 200) {
         this.gifterSelfInfo = data.data;
        }else {
          Toast(data.message || this.$t('failed'))
        }
      });
    },
    //分页
    pageScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      // 检查是否滚动到底部
      if (scrollTop + clientHeight >= scrollHeight) {
          if(this.notFast){
              this.page++;
              if(this.rankListFlag == 1){
                this.getRoomRankingInfo();
              }else{
                this.getGifterRankingInfo();
              }
          }
      }
    },
    // 跳转资料页  需要uid
    skipUserInfo(item) {
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['skipUserInfo'].postMessage(JSON.stringify(item.userId));
      } else if (this.client == 'Android') {
        nativeJs["skipUserInfo"](JSON.stringify(item.userId));
      }
    },
    // 跳转房间
    toAppRoom(item){
      if (this.client == 'iOS') {
        window["webkit"].messageHandlers['languageRoom'].postMessage(JSON.stringify(item.roomId));
      } else if (this.client == 'Android') {
        nativeJs["languageRoom"](JSON.stringify(item.roomId));
      }
    },
    // 获取个人收礼榜单
    getAnchorsRankingInfo(){
      let that = this
      this.notFast = false;
      let paramObjs = {
        option: 'get',
        host: `${this.$serviceIp}/api/activity/modulerank/role?token=${that.token}&uid=${that.uid}&cfgId=${this.activityObj.id}&page=${this.page}&size=${this.size}&rankType=2`,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.anchorsTopObj.push(...data.data.pager.list)
          //  是否还有下一页
          if(data.data.pager.list.length == data.data.pager.size){
              this.notFast = true
          }
        }
        else {
          Toast(data.message || this.$t('failed'))
        }
      })
    },
    // 获取个人榜单
    getGifterRankingInfo(){
      let that = this
      this.notFast = false;
      let paramObjs = {
        option: 'get',
        host: `${this.$serviceIp}/api/activity/modulerank/role?token=${that.token}&uid=${that.uid}&cfgId=${this.activityObj.id}&page=${this.page}&size=${this.size}&rankType=1`,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.gifterTopObj.push(...data.data.pager.list)
          //  是否还有下一页
          if(data.data.pager.list.length == data.data.pager.size){
              this.notFast = true
          }
        }
        else {
          Toast(data.message || this.$t('failed'))
        }
      })
    },
    // 获取房间榜单
    getRoomRankingInfo(){
      let that = this
      this.notFast = false;
      let paramObjs = {
        option: 'get',
        host: `${this.$serviceIp}/api/activity/modulerank/room?token=${that.token}&uid=${that.uid}&cfgId=${this.activityObj.id}&page=${this.page}&size=${this.size}&rankType=2`,
        data: '',
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(paramObjs).then((data) => {
        if (data.code == 200) {
          this.roomTopObj.push(...data.data.pager.list)
          this.roomSelfInfo = data.data.rank
          //  是否还有下一页
          if(data.data.pager.list.length == data.data.pager.size){
              this.notFast = true
          }
        }
        else {
          Toast(data.message || this.$t('failed'))
        }
      })
    },
    getUserInfo(data) {
      this.userInfo = JSON.parse(data);
      this.headers = [
        'Content-Type:' + 'application/json; charset=utf-8',
        'deviceId:' + JSON.parse(data).deviceId,
        'lang:' + JSON.parse(data).lang,
        'version:' + JSON.parse(data).version,
        'os:' + JSON.parse(data).os,
        'model:' + JSON.parse(data).model,
        'fid:' + JSON.parse(data).fid,
        'uid:' + JSON.parse(data).userId
      ]
      this.token = JSON.parse(data).token
      this.uid = JSON.parse(data).userId
      let langs = this.userInfo.lang.substring(0,2)
      let lang = this.userInfo.lang
    // this.token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiJNak0xTmpZNCIsInh5eiI6Ik1EVTJaakkyTmpJd1pqVm1OR1JrTUdKbE1tSTFNR0ptTldNM1pHWmxORFk9IiwiZXhwIjoxOTMxMDg4NTg1fQ.bNewJwiTDXAZvujxKZmYT2DrYb0KnU1VdYGsvLfghSI'
    //   this.uid = '235668'      
    //   this.headers = [
    //     'Content-Type:' + 'application/json; charset=utf-8',
    //     'deviceId:' + '056f26620f5f4dd0be2b50bf5c7dfe46',
    //     'lang:' + 'zh_CN',
    //     'version:' + '1.31.1',
    //     'os:' + '1',
    //     'model:' + 'MI8',
    //     'fid:' + '1001'
    //   ]
    //   let langs = 'en'
    //   let lang = 'zh_CN'
      if (langs == "ar") {
          this.$i18n.locale = "ar";
          document.title = 'حدث ليلي لكورة القدم'
      } else {
          this.$i18n.locale = "en";
          document.title = 'Layla Football Event'
      }
      document.querySelector("html").setAttribute("lang", this.$i18n.locale);
      // 获取活动时间
      let personLeveldatas = {
        option: "get",
        host:
          this.$serviceIp +
          "/api/activity/modulerank/cfg?token=" +
          this.token +
          "&uid=" +
          this.uid,
        data: "",
        header: JSON.stringify(this.headers),
      };
      this.$server.curlExec(personLeveldatas).then((data) => {
        if (data.code == 200) {
          this.activityObj = data.data.cfgList.find((item)=>{
            return item.id == this.activityId
          })

          this.luckScore = data.data.luckScore;
          if(this.activityObj && this.activityObj.endTime > new Date().getTime() && this.activityObj.startTime < new Date().getTime()) {
            this.activeState = 2;
            // 设置倒计时
            this.countActivityTime()
            // 获取房间榜单
            this.getRoomRankingInfo()
        } else if(this.activityObj && this.activityObj.startTime > new Date().getTime()){

            this.activeState = 1;
            // 设置倒计时
            this.countActivityTime()
            // 获取房间榜单
            this.getRoomRankingInfo()
        } else {
          this.activeState = 0;
            // 获取房间榜单
            this.getRoomRankingInfo()
          // Toast(data.message || this.$t('failed'))
        }
      }
      });
      // this.getGiftList()
    },
    // 计算活动时间
    countActivityTime(){
      // let startTime = new Date(this.activityObj.startTime).getTime();
      // let endTime = new Date(this.activityObj.endTime).getTime();
      // let currentTime = new Date().getTime();
      // let time = endTime - currentTime;
      // let day = Math.floor(time / (1000 * 60 * 60 * 24));
      // let hour = Math.floor((time / (1000 * 60 * 60)) % 24);
      // let minute = Math.floor((time / (1000 * 60)) % 60);
      // let second = Math.floor((time / 1000) % 60);
      // this.activeTime = day + '天' + hour + '小时' + minute + '分钟' + second + '秒';
      let endTime = 0;
      if(this.activeState == 1){
        this.countdown(this.activityObj.startTime)
      }else{
        this.countdown(this.activityObj.endTime)
      }
      
      if(new Date().getTime() > this.activityObj.startTime && new Date().getTime() > this.activityObj.endTime){
        this.countdown(this.activityObj.endTime)
      }

    },
    // 根据时间戳转换倒计时
    countdown(endTime) {
      this.timer = setInterval( ()=> {
        const now = new Date().getTime();
        const distance = endTime - now;
        if (distance <= 0) {
          clearInterval(this.timer);
          return;
        }
        const days = Math.floor(distance / (1000 * 60 * 60 )) > 99 ? 99 : Math.floor(distance / (1000 * 60 * 60 ));
        // const hours = this.zeroFill(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
        const hours = this.zeroFill(days);
        const minutes = this.zeroFill(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
        const seconds = this.zeroFill(Math.floor((distance % (1000 * 60)) / 1000));
        this.days = days;
        this.hours = hours;
        this.minutes = minutes;
        this.seconds = seconds;
      }, 1000);
    },
    zeroFill(num){
      if(num < 10){
        return [0,num]
      }else{
        return [num.toString().slice(0,1),num.toString().slice(1)]
      }
    },
    // 切换展示规则tab
    changeRulesTab(index){
      this.rulesTab = index;
    },
    // 显示隐藏规则弹窗
    changePopFlag(type){
      this.popFlag = type;
      if(type == 1){
        // 禁止页面滚动
        this.stopScroll();
      }else{
        this.canScroll()
      }
    },
    // 切换榜单展示
    changeRankListFlag(type){
      // if(this.activeState == 0) return;
      this.rankListFlag = type;
      this.page = 0;
      this.roomTopObj = [];
      this.gifterTopObj = [];
      this.anchorsTopObj = [];
      if(type == 1){
        this.getRoomRankingInfo()
      }else if(type == 2){
        this.getGifterRankingInfo();
        this.getGifterSelfRankInfo()
      }else{
        this.getAnchorsRankingInfo();
        this.getAnchorsSelfRankInfo();
      }
    },
    // 判断平台
    judgeClient() {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) { //判断iPhone|iPad|iPod|iOS
        this.client = 'iOS';
      } else if (/(Android)/i.test(navigator.userAgent)) { //判断Android
        this.client = 'Android';
      } else {
        this.client = 'PC';
      }
      return this.client;
    },

    
  },
};
</script>

<style scoped>
@import './index.css';

@import '../../assets/gradeIcon/gradeIcon.css';
</style>
